.App {
}

.Page {
  background-color: white;
}

.Opening-Section {
  text-align: center;
  background-image: url(./opeingImage.jpg);
  position: relative;

    /* Full height */
  height: 100vh;
  width: 100%;

    /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.FirstName {
  text-align: center;
  position: absolute;
  color: white;
  font-size: 6vw;
  margin-left: 23vw;
  margin-top: 16vw;
}

.LastName {
  text-align: center;
  position: absolute;
  color: white;
  margin-left: 35vw;
  margin-top: 22vw;
  font-size: 6vw;
}

.Rectangle {
  display: inline-block;
  width: 60vw;
  height: 15vw;
  background: black;
  margin-top: 15vw;
  margin-left: auto;
  margin-right: auto;
  background-color: #3d3c3c;
}

.Title {
  text-align: center;
  position: relative;
  color: white;
  font-size: 4vw;
}
.Welcome {
  font-size: 1.5vw;
  text-align: center;
  position: relative;
  color: white;
}

.Down-Arrow {
  position: relative;
  margin-top: 2.5vw;
}

.Main-Body {
  background-color: #f0f0f0;
}

.Work-Experience {
  background-color: #f0f0f0;
}

.Projects {
  background-color: #e3e3e3;
}

.Body-Header {
  padding-top: 50px;
  font-size: 50px;
  margin-bottom: -80px;
}

.Footer {
  background-color: #3d3c3c;
}

.Footer-Text-Left {
  color: white;
  vertical-align: center;
  padding-left: 50px;
  padding-top: 20px;
  padding-bottom: 30px;
}

.Footer-Text-Right {
  color: white;
  text-align: right;
  vertical-align: center;
  padding-right: 50px;
}




* {
        margin: 0;
        padding: 0;
        border: 0;
        outline: 0;
        font-size: 100%;
        vertical-align: baseline;
        background: transparent;
    }
